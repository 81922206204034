import Hamburger from 'hamburger-react'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IoMdArrowBack } from "react-icons/io"

const enlightenable = require("../..//assets/eeLogo.png")
const knowyourbuilding = require("../../assets/kybLogo.png")
const enliteBlue = require("../../assets/logo/blue.png")

interface IProps{
    isModalOpen: boolean,
    toggleModal: React.Dispatch<React.SetStateAction<boolean>>
    setOpenEarlyAccessModal: any,
    setEarlyAccessModalType: any
}

const ProductCard = (
    {type, title, description, setOpenEarlyAccessModal, setEarlyAccessModalType} : 
    {type: 'kyb'|'ee', title: string, description: string, setOpenEarlyAccessModal:any, setEarlyAccessModalType: any}
) => {
        return (
            <div className={`grid text-black font-normal text-left p-4 px-6 hover:bg-neutral-100 item-${type}`} style={{gridTemplateColumns:'25% 75%', borderRadius:'4px'}}
                    onClick={()=>{
                        setOpenEarlyAccessModal(true); 
                        setEarlyAccessModalType(type)
                        const blurBG = document.getElementById('blur-bg');
                        blurBG?.classList.add('blur')
                        blurBG?.classList.add('stop-scroll')
                    }}
                    >
                <div className='flex items-center justify-center'>
                    <img src={type === 'kyb' ? knowyourbuilding : enlightenable} alt='logo' className='w-6/12'/>
                </div>
                <div>
                    <h1 className='text-2xl mb-4'>{title}</h1>
                    <p className='text-neutral-500 text-sm'>{description}</p>
                </div>
            </div>
        )
}
const ProductsHamburgerMenu = (
    {showProducts, setShowProducts, setOpenEarlyAccessModal, setEarlyAccessModalType, toggleModal}:
    {showProducts: any, setShowProducts: any, setOpenEarlyAccessModal:any, setEarlyAccessModalType: any, toggleModal:any}
) => {

    useEffect(() => {
        if(showProducts)
        {
            const blurBG = document.getElementById('blur-bg');
            blurBG?.classList.add('blur')
            blurBG?.classList.add('stop-scroll')
        }
    },[showProducts])
    
    return (
        <>
        {
            showProducts 
            ?
            (
                <div className='w-screen h-screen bg-white flex flex-col text-4xl gap-32 fixed top-0 z-[600] sm:hidden'>
                    <div className='text-left bg-white grid p-6 text-primary grid-cols-2 gap-[60%] transition-all duration-400'>
                        <button className='inline-block font-bold text-3xl sm:hidden' onClick={() => {setShowProducts(false)}}>
                            <IoMdArrowBack />
                        </button>
                        <NavLink to="/" onClick={() => {
                            setShowProducts(false)
                            toggleModal(false)
                        }}>
                            {/* <h1 className='inline-block font-bold text-3xl'>enlite</h1> */}
                            <img src={enliteBlue} alt="enlite" className='w-28'/>
                        </NavLink>
                    </div>
                    <div className='flex flex-col justify-center items-center gap-16 text-neutral-600'>
                        <ProductCard 
                            title='Know Your Building' 
                            description='KYB is the world s first operating system to measure, monitor, manage & monetize real estate' 
                            // logo={knowyourbuilding} 
                            type='kyb'
                            setOpenEarlyAccessModal={setOpenEarlyAccessModal}
                            setEarlyAccessModalType={setEarlyAccessModalType}
                            // setModalOpen={setModalOpen}
                            // setModalType={setModalType}
                            />
                        <ProductCard 
                            title='Enlite Enable' 
                            description='Enlite Enable is your digital access identity for the physical world. Unlock spaces and authenticate users in real-time using their mobile devices.' 
                            type='ee' 
                            setOpenEarlyAccessModal={setOpenEarlyAccessModal}
                            setEarlyAccessModalType={setEarlyAccessModalType}
                            // logo={enlightenable}
                            // setModalOpen={setModalOpen}
                            // setModalType={setModalType}
                            />
                    </div>
                </div>
            )
            :
            null
        }
        </>
    )
}

const HamburgerMenu = ( {isModalOpen, toggleModal, setOpenEarlyAccessModal, setEarlyAccessModalType} : IProps) => {

    const [showProducts, setShowProducts] = useState<boolean>(false)

    return (
        <>
        {
            isModalOpen ?
                (
                    <>
                        <div className='w-screen h-screen overflow-y-scroll bg-white flex flex-col text-4xl gap-32 fixed top-0 z-[500] sm:hidden'>
                            <div className='text-left h-24 bg-white grid px-6 pb-0 text-primary grid-cols-2 gap-[60%] transition-all duration-400 md:py-6'>
                                <button className='inline-block font-bold text-3xl sm:hidden'>
                                    <Hamburger toggled={isModalOpen} toggle={toggleModal}/>
                                </button>
                                <NavLink to="/"
                                className='flex items-center'
                                onClick={() => {
                                    setOpenEarlyAccessModal(false)
                                    toggleModal(false)
                                }}>
                                    {/* <h1 className='inline-block font-bold text-3xl'>enlite</h1> */}
                                    <img src={enliteBlue} alt="enlite" className='w-32'/>
                                </NavLink>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-16 text-neutral-600'>
                                <NavLink end to="/"
                                    style={({ isActive }) => {
                                        return {
                                            fontWeight: isActive ? 'bold' : 'normal',
                                            color: isActive ? "#1f54c5" : 'rgb(82 82 82 / var(--tw-text-opacity))'
                                        }
                                    }}
                                    onClick={() => { toggleModal(!isModalOpen) }}
                                >
                                    Home
                                </NavLink>
                                <h1
                                    onClick={() => { setShowProducts(true) }}
                                >
                                    Products
                                </h1>
                                <NavLink end to="/contact" 
                                    style={({ isActive }) => {
                                        return {
                                            fontWeight: isActive ? 'bold' : 'normal',
                                            color: isActive ? "#1f54c5" : 'rgb(82 82 82 / var(--tw-text-opacity))'
                                        }
                                    }}
                                    onClick={() => { toggleModal(!isModalOpen) }}
                                >
                                    Contact
                                </NavLink>
                                <NavLink end to="/career" 
                                    style={({ isActive }) => {
                                        return {
                                            fontWeight: isActive ? 'bold' : 'normal',
                                            color: isActive ? "#1f54c5" : 'rgb(82 82 82 / var(--tw-text-opacity))'
                                        }
                                    }}
                                    onClick={() => { toggleModal(!isModalOpen) }}
                                >
                                    Career
                                </NavLink>
                            </div>
                        </div>
                        <ProductsHamburgerMenu toggleModal={toggleModal} showProducts={showProducts} setShowProducts={setShowProducts} setOpenEarlyAccessModal={setOpenEarlyAccessModal} setEarlyAccessModalType={setEarlyAccessModalType} />
                    </>
                )
                :
                (
                    null
                )
        }
        </>
    )
}

export default HamburgerMenu
