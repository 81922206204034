import React, { useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillShareFill } from 'react-icons/bs'
import { IoLocationSharp } from 'react-icons/io5'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {useDropzone} from 'react-dropzone';
import { useSnackbar } from 'notistack'
import { API_BASE_URL } from '../../global/api_url'
import axios from 'axios'

import CountryCodeJSON from '../../global/country_codes.json';

interface JobDetailsInteface {
    jobDeptOverview: string,
    jobDutiesAndSkills: string,
    jobID: string,
    jobLocation: string,
    jobGoodToHave: string,
    jobTitle: string,
    jobType: string
}

const JobApplication = () => {

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
    const [file, setFile] = useState<any>()
    const [error, setError] = useState<string>('')
    
    const {jobID} = useParams()

    const [submitingData, setSubmitingData] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<boolean>(false)
    const [about, setAbout] = useState<string>('')
    const [countryCode, setCountryCode] = useState<string>('+91')
    const [emptyField, setEmptyField] = useState<boolean>(false)

    const [jobDetails, setJobDetails] = useState<JobDetailsInteface>({
        jobDeptOverview: '',
        jobDutiesAndSkills: '',
        jobID: '',
        jobLocation: '',
        jobGoodToHave: '',
        jobTitle: '',
        jobType: ''
    })

    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => { //go back to top: ;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[])
    
    const handleClickApplyNow = async (e: any) => {
        e.preventDefault();
        setSubmitingData(true)
        setEmptyField(false)
        if(name === '' || email === '' || number === '' || about === '' || file === undefined || emailError)
        {
            setEmptyField(true)
            enqueueSnackbar(
                'Please fill all the fields',
                {
                    variant:'error'
                }
            )
        }
        else
        {
            try{
                console.log(file)
                let data = new FormData()
                data.append('name', name)
                data.append('number', number)
                data.append('email', email)
                data.append('about', about)
                data.append('resume', file)
                const res = await axios.post(
                    `${API_BASE_URL}/apply-for-job/${jobID}`,
                    data
                )
                enqueueSnackbar(
                    res.data.message,
                    {
                        variant:'success'
                    }
                )
                setName('')
                setNumber('')
                setEmail('')
                setAbout('')
                setFile(undefined)
            }
            catch(err: any){
                console.log(err.message)
            }
        }
        setSubmitingData(false)
    }

    useEffect(() => {
        if(acceptedFiles && acceptedFiles[0]?.size > 5*1024*1024){
            setError('File chosen is greater than 5 MB, please select a different file.')
            setFile(null)
            return 
        }
        if(acceptedFiles.length > 0){
            setFile(acceptedFiles[0])
            setError('')
        }
    }, [acceptedFiles])

    let navigate = useNavigate();
    useEffect(() => {
        const fetchJobDetails = async () => {
            try{
                const res = await axios.get(
                    `${API_BASE_URL}/job-details/?jobID=${jobID}`,
                )
                setJobDetails(res.data.result[0])
            }
            catch(err){
                console.log(err)
                navigate('/')
            }
        }
        fetchJobDetails()
    },[jobID, navigate])

    return (
            <div className='bg-white py-16 top-20 left-0 z-[900]  md:grid md:grid-cols-2' data-aos="fade-right" data-aos-duration='2--'>
                <div className='flex flex-col gap-7 md:px-24'>
                    <div className='px-6 flex flex-row text-primary font-bold text-2xl gap-5  sm:gap-10'>
                        <Link to="/career" className='text-3xl font-bold inline-block'>
                            <BiArrowBack />
                        </Link>
                        <h1>{jobDetails.jobTitle}</h1>
                    </div>
                    <div className='px-6 flex gap-3'>
                        <div className='flex text-xs bg-neutral-100 py-2 px-4 justify-center items-center rounded-lg text-neutral-500 gap-2'>
                            <IoLocationSharp />
                            {jobDetails.jobLocation}
                        </div>
                        <div className='flex text-xs bg-blue-100 py-2 px-4 justify-center items-center rounded-lg text-blue-500 gap-2'>
                            {jobDetails.jobType}
                        </div>
                    </div>
                    <button className= 'px-6 text-neutral-500 flex items-center gap-5 w-fit' onClick={() => navigator.share({
                        title: jobID,
                        text: "Share",
                        url: window.location.href
                    })}>
                        <BsFillShareFill />
                        Share this job
                    </button>
                    <div className='px-6 text-md flex flex-col gap-5'>
                        <h1>Department Overview</h1>
                        <p className='text-neutral-500 font-light'>
                            {jobDetails.jobDeptOverview}
                        </p>
                    </div>
                    <a href="#apply-now" className='bg-primary w-full text-white font-bold text-center uppercase text-xl py-4 md:hidden'>Apply Now</a>
                    <div className='px-6 gap-5 flex flex-col text-neutral-600'>
                        <h1 className='text-md'>Duties & Skills</h1>
                        {}
                        <ol className='text-md flex flex-col list-decimal gap-2 font-light px-2' type='1'>
                            {
                                jobDetails.jobDutiesAndSkills && jobDetails.jobDutiesAndSkills.split('|').map(duty => {
                                    return <li>{duty}</li>
                                })
                            }
                        </ol>
                    </div>
                    <div className='px-6 gap-5 flex flex-col text-neutral-600'>
                        <h1 className='text-md'>Good to have</h1>
                        <ol className='text-md flex flex-col list-decimal gap-2 font-light px-2' type='1'>
                            {
                                jobDetails.jobGoodToHave && jobDetails.jobGoodToHave.split('|').map(skill => {
                                    return <li>{skill}</li>
                                })
                            }
                        </ol>
                    </div>
                </div>
                <form className='flex gap-8 flex-col relative px-6 pt-10 md:px-20' id="apply-now">
                    <h1 className='text-2xl'>Apply Now</h1>
                    <div className='flex flex-col'>
                        <label className='text-primary'>Name</label>
                        <input type='text' placeholder='John Kevine' 
                        className={`${emptyField && name.length === 0 ? 'border-red-400' : 'border-primary'} border-2 p-2 mt-2`}
                        required value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-primary'>Mobile Number</label>
                        <div className='grid grid-cols-7 gap-5'>
                        <select className={`bg-white col-span-2 flex border-2 ${emptyField && countryCode === '' ? 'border-red-400' : 'border-primary'} items-center justify-center mt-2 w-full text-center`} 
                                value={countryCode} onChange={e => setCountryCode(e.target.value)}>
                            {
                                CountryCodeJSON.map(
                                    country => {
                                        return (
                                            <option selected={country.dial_code === "+91"} value={country.dial_code}>
                                                {`${country.name} (${country.dial_code})`}
                                            </option>
                                        )
                                    }
                                )
                            }        
                        </select>
                        <input type='number' min='0' placeholder='0123456789' 
                                className={`${emptyField && number.length === 0 ? 'border-red-400' : 'border-primary'} col-span-5 border-2 p-2 mt-2`} 
                                required value={number} 
                                onChange={e => setNumber(e.target.value)}
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                />
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-primary'>E - mail</label>
                        <input 
                                type='email' 
                                placeholder='johnkevin@email.com' 
                                className={`col-span-6 border-2 p-2 mt-2 ${emailError || (emptyField && email.length === 0) ? 'border-red-500' : 'border-primary'}`}
                                required 
                                value={email} 
                                onChange={e => {setEmail(e.target.value); setEmailError(false)}}
                                onBlur={ 
                                    () => 
                                    !(/\S+@\S+\.\S+/).test(email) ? setEmailError(true) : setEmailError(false)
                                }
                                />
                            {
                                emailError 
                                    ?
                                    <label className='text-red-400 text-xs py-1'>Please enter valid email address</label>
                                    :
                                    null
                            }
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-primary'>What would you like us to know ?</label>
                        <textarea placeholder='Interior Designer' 
                                    className={`${emptyField && about.length === 0 ? 'border-red-400' : 'border-primary'} border-2 p-2 mt-2`} 
                                    required value={about} onChange={e => setAbout(e.target.value)}/>
                    </div>
                    <div className='flex flex-col gap-3 text-primary'>
                        <label className='text-primary'>Resume</label>
                        <section className="container">
                        <div {...getRootProps({className: 'dropzone'})} 
                            className={`border-2 ${emptyField && file === undefined ? 'border-red-400' : 'border-neutral-400'} border-dashed text-center p-6`}>
                            <input {...getInputProps()} type='file' multiple={false} accept=".pdf, .doc, .docx"/>
                            <p className='text-black font-light'>Drop your resume here or 
                                <p className='text-primary inline-block pl-1'>{`Browse`}</p>
                            </p>
                            <p className='text-neutral-400 font-light'>Max size : 5MB (doc, pdf, docx)</p>
                        </div>
                        {
                            file && (
                                <aside className='flex flex-col gap-3 py-3'>
                                    <h4>Uploaded Resume : </h4>
                                    <ul className='text-black text-center'>{file.name}</ul>
                                </aside>
                            )
                        }
                        {
                            error && (
                                <div className='bg-red-100 text-red-600 p-4 text-center mt-4 rounded-lg'>
                                    {error}
                                </div>
                            )
                        }
                        </section>
                    </div>
                    <button className='bg-primary w-ful text-white p-4 py-3 uppercase disabled:bg-blue-200' disabled={submitingData} onClick={handleClickApplyNow}>
                        {
                            submitingData ? 'Submiting Form..' : 'Apply Now'
                        }
                    </button>
                </form>
            </div>
    )
}
export default JobApplication
