import React from 'react'
import { Link } from 'react-router-dom'
import { BsTwitter} from "react-icons/bs"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa"
const enliteBlue = require("../../assets/logo/blue.png")

const Footer = ({setOpenProductMenu, setBlueNavbar}: any) => {
    return (
        <>
            <div className='' data-aos-duration='200'>
                <div className='w-10/12 m-auto border-t-[1px] border-t-neutral-300 py-6 
                                xl:p-6 xl:9/12'>
                    <div className='hidden text-right text-neutral-400 xl:block font-light'>  &#169; EnliteResearch, {new Date().getFullYear()} </div>
                    {/* <div className='hidden text-right text-neutral-400 xl:block font-light'>  &#169; EnliteResearch, 2020 </div> */}
                    <div className='grid grid-cols-2 gap-[-70%]'>
                        <div className='grid text-neutral-400 grid-rows-2 xl:grid-cols-2'>
                            <Link to="/" onClick={()=>{
                                    setOpenProductMenu(false);
                                    setBlueNavbar(true)
                                }}>
                                {/* <h1 className='inline-block font-bold text-4xl text-primary'>enlite</h1> */}
                                <img src={enliteBlue} alt="enlite" className='w-28'/>
                            </Link>
                            <div className='grid grid-cols-none
                                            xl:grid-cols-3 xl:gap-16'>
                                <button className='flex items-center' 
                                onClick={() => {
                                    // console.log(setProductModalOpen)
                                    setOpenProductMenu(true);
                                    setBlueNavbar((prevVal: boolean) => !prevVal)
                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                }}>Products</button>
                                <Link to="/contact" className='flex items-center' onClick={()=>{
                                    setOpenProductMenu(false);
                                    setBlueNavbar(false)
                                }}>Contact</Link>
                                <Link to="/career" className='flex items-center'  onClick={()=>{
                                    setOpenProductMenu(false);
                                    setBlueNavbar(false)
                                }}>Career</Link>
                            </div>
                        </div>
                        <div className='grid text-neutral-400 grid-rows-2 xl:grid-cols-1'>
                            <div className='text-right text-neutral-400 xl:hidden'>  &#169; EnliteResearch, {new Date().getFullYear()} </div>
                            {/* <div className='text-right text-neutral-400 xl:hidden'>  &#169; EnliteResearch, 2020 </div> */}
                            <div className='grid grid-row-3 text-right text-black text-2xl   
                                            xl:flex justify-end'>
                                <a href="https://www.facebook.com/Enlite-100325725311783/"  rel="noreferrer" target="_blank" className='flex items-end justify-end py-2 xl:mr-6  '>
                                    <FaFacebookF />
                                </a>
                                <a href="https://twitter.com/Enlite_Official" target="_blank"  rel="noreferrer" className='flex items-end justify-end py-2 xl:mr-6  '>
                                    <BsTwitter />
                                </a>
                                <a href="https://www.linkedin.com/company/enliteresearch/" target="_blank"  rel="noreferrer" className='flex items-end justify-end py-2'>
                                    <FaLinkedinIn />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </>
    )
}

export default Footer
