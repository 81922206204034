import React from 'react'
import { Link } from 'react-router-dom'

const error = require("../../assets/404.png")

const NotFound = () => {
    return (
        <div className='bg-blue-50 h-screen w-screen z-[900] fixed gap-10 text-center top-0 left-0 flex flex-col items-center justify-center text-2xl'>
            <img src={error} alt="404 Error" className='w-96'/>
            <h1>
                Sorry the page you are looking for doesn't exist
            </h1>
            <Link to="/" className='bg-primary text-2xl px-10 text-white uppercase py-5 '>Go Home</Link>
        </div>
    )
}

export default NotFound
