import React from 'react'

const enlightenable = require("../../assets/eeLogo.png")
const knowyourbuilding = require("../../assets/kybLogo.png")

const ProductMenuCard = (
    {title, description, logo, type, setModalOpen, setModalType}:
    {title: string, description: string, logo: any, type: string, setModalOpen: any, setModalType: any}
) => {
    return (
            <div className={`grid text-black font-normal text-left p-4 px-6 hover:bg-neutral-100 item-${type}`} style={{gridTemplateColumns:'25% 75%', borderRadius:'4px'}}
                    onClick={()=>{
                        if(type === 'kyb'){
                            window.open("https://knowyourbuilding.com/")
                        }
                        else{
                            setModalOpen(true); 
                            setModalType(type)
                            const blurBG = document.getElementById('blur-bg');
                            blurBG?.classList.add('blur')
                            blurBG?.classList.add('stop-scroll')
                        }
                    }}
                    >
                <div className='flex items-center justify-center'>
                    <img src={logo} alt={logo}/>
                </div>
                <div>
                    <h1>{title}</h1>
                    <p className='text-neutral-600 text-sm'>{description}</p>
                </div>
            </div>
    )
}
const ProductMenu = (
    {setModalOpen, setModalType, openProductMenu} : {setModalOpen: any, setModalType: any, openProductMenu: boolean}
) => {
    return (
        <>
            {
                openProductMenu
                ?
                (
                    <div className='top-20 w-full z-[102] text-2xl bg-white text-primary absolute shadow-2xl left-0  py-10 grid-cols-2 gap-16 md:grid md:px-12 xl:px-64' style={{}}>
                        <ProductMenuCard 
                            title='Know Your Building' 
                            description='KYB is the world s first operating system to measure, monitor, manage & monetize real estate' 
                            logo={knowyourbuilding} 
                            type='kyb'
                            setModalOpen={setModalOpen}
                            setModalType={setModalType}
                            />
                        <ProductMenuCard 
                            title='Enlite Enable' 
                            description='Enlite Enable is your digital access identity for the physical world. Unlock spaces and authenticate users in real-time using their mobile devices.' 
                            type='ee' 
                            logo={enlightenable}
                            setModalOpen={setModalOpen}
                            setModalType={setModalType}
                            />
                    </div>
                )
                :
                    null
            }
        </>
    )
}

export default ProductMenu
