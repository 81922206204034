import React, { useEffect, useRef, useState } from 'react'
import { MOBILE } from '../../global/screen_size'
import { BsArrow90DegDown } from "react-icons/bs"
import { BsArrowUpRight } from "react-icons/bs"
import { AiFillBulb }  from "react-icons/ai"
import { BsFillTreeFill } from "react-icons/bs"
import { IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5'

const KYB = require("../../assets/kyb.png")
const EE = require("../../assets/ee.png")
const ola = require("../../assets/ola.png")
const tata = require("../../assets/tata.png")
const cbre = require("../../assets/cbre.png")
const lt = require("../../assets/lt.png")
const justdial = require("../../assets/justdial.png")
const iss = require("../../assets/iss.png")
// const brinc = require("../../assets/brinc.png")
const kybLogo = require("../../assets/kybLogo.png")
const eeLogo = require("../../assets/eeLogo.png")
const quess = require("../../assets/quess.png")
const ad = require("../../assets/ad.png")
const thebetterindia = require("../../assets/thebetterindia.png")
const industrialautomation = require("../../assets/industrialautomation.png")
const yourstory = require("../../assets/yourstory.png")
const qualcom = require("../../assets/qualcom.png")
const toi = require("../../assets/toi.png")
const gadgetsnow = require("../../assets/gadgetsnow.png")
const nasscom = require("../../assets/nasscom.png")

const gaurav = require("../../assets/gaurav.png")
const garima = require("../../assets/garima.png")

const FounderCard = (
    {imgURL, name, designation, description}:
    {imgURL: string, name: string, designation: string, description: string}
) => {
    // let class = ['shadow-xl', 'md:max-h-96', 'w-7/12', 'relative', 'z-20', 'object-cover', '2xl:w-fit', 'w-full'].join(' ')
    const classes = ['shadow-xl', 'md:max-h-96', 'w-7/12', 'relative', 'z-20', 'object-cover', '2xl:w-fit', 'w-full'];
    name === 'Gaurav Bali'? classes.push('gaurav-card-width') : classes.push('garima-card-width')
    // if(){
        
    // }

    return(
        <div className='flex flex-col gap-5 py-5 xl:px-0 xl:gap-7'>
            <img src={imgURL} alt={name} className={classes.join(' ')} data-aos='fade-in'/>
            <div>
                <h1 className='text-primary text-2xl xl:text-4xl font-normal xl:py-1' data-aos='fade-in'>{name}</h1>
                <h2 className='text-black' data-aos='fade-in'>{designation}</h2>
            </div>
            <p className='text-neutral-400 font-light xl:w-10/12' data-aos='fade-in'>{description}</p>
        </div>
    )
}

const ProductCard = (
    {title, description, assetURL, logoURL, changeOrderForMobile, setModalOpen, setModalType, modalType, mobile_description} : 
    {title: string, description: string, assetURL?: string, logoURL: any, changeOrderForMobile: boolean, setModalOpen: any, setModalType: any, modalType: any, mobile_description?: any}
) => {
    return(
        <div className='text-black z-10 relative px-2 py-10 gap-6 flex flex-col 
                        sm:grid sm:grid-cols-2 sm:gap-0
                        xl:grid-cols-5  xl:px-32 xl:pt-32 xl:gap-20
                        md:px-20 
                        2xl:px-72'  
                data-aos="fade-in">
            {
                changeOrderForMobile && window.innerWidth > MOBILE
                ?
                (
                    <>
                        <div className='h-[28rem] flex items-center justify-end sm:justify-start sm:relative sm:z-10 xl:col-span-2'>
                            <img src={assetURL} alt="dummy" className='w-full '/>
                        </div>
                        <div className='sm:gap-4 sm:flex sm:flex-col sm:justify-end xl:col-span-3 xl:px-10 2xl:pt-10 xl:font-light'>
                            <div className='text-2xl font-bold flex flex-row sm:flex-col sm:text-4xl sm:gap-4'>
                                {/* <img src={logoURL} alt="modalType" className='w-12'/> */}
                                <h1 className='ml-4 sm:ml-0 font-normal'>{title} {modalType === 'kyb' ? <p className='inline-block'>&trade;</p> : ''}</h1>
                            </div>
                            <p className='text-xl my-4 text-neutral-600 pr-0 sm:pr-8'>{description}</p>
                            <button className='bg-primary px-6 text-white font-bold py-3 uppercase w-44'
                                onClick={()=>{window.open("https://knowyourbuilding.com/")}}
                            >Know More</button>
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div className='sm:gap-4 sm:flex sm:flex-col sm:justify-end xl:col-span-3 xl:px-10 xl:font-light'>
                            <div className='text-2xl font-bold h-8 md:h-fit flex flex-row sm:flex-col sm:text-4xl sm:gap-4'>
                                {/* <img src={logoURL} alt="modalType" className={`h-full md:w-12`}/> */}
                                <h1 className='ml-4 sm:ml-0 font-normal flex items-center'>{title} {modalType === 'kyb' ?  <p className='inline-block'>&trade;</p> : ''}</h1>
                            </div>
                            <p className='text-md my-4 text-neutral-600 pr-0 sm:pr-8 md:text-xl'>{mobile_description ? mobile_description : description}</p>
                            <button className='bg-primary px-6 text-white font-bold py-3 uppercase w-44'
                                    onClick={()=>{setModalType(modalType); setModalOpen(true)}}
                                >Know More</button>
                            </div>
                        <div className='h-[28rem] flex items-center justify-center sm:justify-start sm:relative sm:z-10 xl:col-span-2'>
                            <img src={assetURL} alt="dummy" className={`h-full ${modalType === 'ee' ? 'relative md:top-12' : ''} `}/>
                        </div>
                    </>
                )
            }
        </div>
    )
}

const DriveCard = (
    {title, description, logo, fgColor, bgColor} :
    {title: string, description: string, logo: any, fgColor: string, bgColor:string}
) => {
    return (
        <div className='shadow-md relative z-20 bg-white p-8 text-black sm:py-20  sm:px-4 md:px-10 lg:px-16 xl:p-12 '  data-aos="fade-in" style={{boxShadow:'6px 6px 20px rgba(0, 0, 0, 0.08)'}}>
            <div className='grid py-3 sm:flex sm:flex-col sm:gap-10' style={{gridTemplateColumns:"30% 70%"}}>
                <span className='h-12 w-12 flex items-center justify-center text-2xl sm:text-6xl sm:w-16 sm:h-16 xl:p-4 xl:w-24 xl:h-24' style={{color:fgColor, backgroundColor: bgColor, borderRadius:'4px'}}>
                    {logo}
                </span>
                <h1 className='text-2xl font-normal items-center flex sm:text-2xl lg:text-4xl xl:text-3xl'>{title}</h1>
            </div>
            <div className='text-neutral-500 pt-5 text-xl font-light xl:text-lg'>
                {description}
            </div>
        </div>
    )
}

const AwardCard = (
    {title, description, logo, articleLink, index} :
    {title: string, description: string, logo: string, articleLink?: string, index?: string}
) => {
    return (
        // <div className='p-4'> 
            <div className={`p-8 text-black z-10 bg-white relative sm:py-20  sm:px-16 xl:py-10 xl:px-16`}
                    id={index}
                    style={{boxShadow:'6px 6px 20px rgba(0, 0, 0, 0.08)'}}
                    data-aos="fade-in">
                <div className='py-3 flex flex-col gap-5 text-left w-10/12 sm:gap-10 xl:py-1'>
                    <h1 className='text-xl font-normal text-neutral-600 items-center flex' >{title}</h1>
                    <img src={logo} alt={logo} className='h-12 w-6/12 object-contain text-2xl sm:text-6xl' />
                </div>
                <div className='text-neutral-500 pt-5 font-light'>
                    {description} 
                    {
                        articleLink
                            ?
                            (
                                <a target="_blank" rel='noreferrer' href={articleLink} className='text-primary font-normal'>{` Read More`}</a>
                            )
                            : 
                            null
                    }
                </div>
            </div>
        // </div>
    )
}
const Home = (
    {setModalOpen, setModalType} : 
    {setModalOpen:any, setModalType:any}
) => {
    useEffect(() => { //go back to top: ;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[])

    const awards = useRef(null)

    const [leftArrowDisabled, setLeftArrowDisabled] = useState<boolean>(true)
    const [rightArrowDisabled, setRightArrowDisabled] = useState<boolean>(false)

    return (
        <div className='relative'>
            <section className='py-10 bg-primary pt-0 px-10 sm:pt-32 sm:pr-96 sm:pl-32 md:pr-40 md:pl-20 xl:pr-50'>
                <h1 className='text-white text-5xl font-bold py-20 leading-tight sm:text-7xl z-[111] relative xl:text-5xl xl:w-10/12 xl:leading-snug 2xl:text-7xl' data-aos="fade-up">
                    We build disruptive & innovative solutions for those who build the world around us
                </h1>
                <h1 className='text-white text-2xl pt-20 leading-normal sm:text-2xl z-[101] relative' data-aos="fade-up">
                    <BsArrow90DegDown className='inline-block mx-6 scale-x-[-1]'/>
                    Scroll for more
                </h1>
                <div className='bg-blue-600 w-28 absolute right-0 h-24 top-[28rem] z-[100] md:w-56'></div>
                <div className='bg-blue-800 w-44 absolute right-0 top-[34rem] h-24 z-[100] md:w-96'></div>
                <div className='bg-blue-900 w-36 absolute right-0 top-[40rem] h-24 z-[100] md:w-72'></div>
            </section>
            <div className='hidden bg-primary h-[27rem]  0 w-full absolute sm:block'></div>
            <section className='text-primary p-10 sm:pl-32 sm:text-white md:pl-20 relative' id="products">
                <h1 className='text-5xl font-bold py-20 md:pt-2 leading-normal sm:text-6xl sm:w-8/12 md:w-6/12 sm:pb-0 sm:relative sm:z-10 xl:text-4xl xl:leading-relaxed xl:w-7/12' data-aos="fade-up">
                    Solutions for every real estate ambition
                </h1>
                <div className='pt-10 2xl:pt-28'>
                    <ProductCard 
                        title="Know Your Building"
                        description='KYB is the world’s first operating system to measure, monitor, manage & monetize real estate.'
                        mobile_description='KYB is redefining building management as a function by creating occupant-centric, automated and connected workspaces.'
                        logoURL={kybLogo}
                        assetURL={KYB}
                        changeOrderForMobile={true}
                        setModalOpen={setModalOpen}
                        setModalType={setModalType}
                        modalType='kyb'
                    />
                    <ProductCard 
                        title="Enlite Enable"
                        description='Enlite Enable is your digital access identity for the physical world. Unlock spaces and authenticate users in real-time using your mobile devices.'
                        logoURL={eeLogo}
                        assetURL={EE}
                        changeOrderForMobile={false}
                        setModalOpen={setModalOpen}
                        setModalType={setModalType}
                        modalType='ee'
                    />
                </div>
                <div className='h-56 w-56 absolute left-0 bottom-96 bg-neutral-50 hidden lg:inline-block' style={{background:'rgba(154, 167, 187, 0.1)'}}></div>
                <div className='h-72 w-96 absolute left-0 bottom-24 bg-neutral-100 hidden lg:inline-block' style={{background:'rgba(171, 191, 219, 0.1)'}}></div>
            </section>
            <section className='text-primary bg-white py-10 relative'>
                <h1 className='text-4xl font-bold leading-normal sm:text-6xl sm:w-6/12 sm:pb-0  px-10 sm:pl-20'  data-aos="fade-up">
                    What drives us
                </h1>
                <p className='text-neutral-600 text-xl px-10 sm:w-7/12 sm:py-8 xl:w-10/12 font-light  sm:pl-20'  data-aos="fade-in" data-aos-delay='500'>
                    We are in the business of creating and delivering value. We build impactful products that break conventions and transform your physical spaces to accommodate your evolving goals
                </p>
                <div className='overflow-scroll w-full h-[450px] overflow-y-hidden sm:h-min  sm:pl-20 xl:px-20 relative' >
                    <div className='grid grid-cols-3 relative z-20 gap-10 w-[1000px] px-10 py-4 sm:px-10 sm:gap-20 sm:w-[1500px] xl:px-0 xl:w-full'>
                        <DriveCard title='Future' 
                                    description='We envision a future where your ecosystem mimics you and grows with you; where your infrastructures support your goals, and we contribute to your success.' 
                                    logo={<BsArrowUpRight />}
                                    fgColor='#DF305B'
                                    bgColor='#FFDEE6'
                                />
                        <DriveCard title='Innovation' 
                                    description='The spirit of innovation defines our drive. We strive to break barriers, empower businesses, and pave the way for others to build on our innovative platforms.' 
                                    logo={<AiFillBulb />}
                                    fgColor='#D1B205'
                                    bgColor='#FFFADE'
                                />
                        <DriveCard title='Environment' 
                                    description='Our gaze toward the future is rooted deeply with an appreciation for the environment and how our eco-friendly products can help implement a lasting change.' 
                                    logo={<BsFillTreeFill />}
                                    fgColor='#03CD7B'
                                    bgColor='#C3F4E0'
                                />
                    </div>
                </div>
                <div className=' bg-neutral-100 xl:h-[10rem] z-10 w-full bottom-0 left-0 absolute sm:block'></div>
            </section>
            <div className=' bg-neutral-100 h-[80rem] -z-10 w-full absolute sm:block sm:h-[60rem]'></div>
            <section className='text-primary bg-neutral-100 relative '>
                <h1 className='text-4xl font-bold leading-normal p-10 sm:text-6xl sm:w-6/12 sm:pb-0 xl:pl-20 xl:w-10/12 xl:leading-relaxed'  data-aos="fade-up">
                    Powering innovation with companies worldwide
                </h1>
                <div className='h-52 w-72 absolute right-0 top-20 hidden lg:inline-block' style={{background: 'rgba(87, 118, 164, 0.1)'}}></div>
                <div className='h-52 w-56 absolute right-0 top-72 hidden lg:inline-block' style={{background: 'rgba(19, 44, 81, 0.2)'}}></div>
                {/* <div className='grid grid-cols-2 sm:grid-cols-5 sm:px-40 sm:h-[500px]' > */}
                    {
                        window.innerWidth > MOBILE
                        ?
                        (
                            <div className='w-full overflow-y-hidden overflow-x-hidden h-fit ' data-aos="fade-in" data-aos-duration='500'>
                                <div className='w-[3500px] grid grid-cols-2 gap-10  h-fit'>
                                    <div className='grid grid-cols-2 sm:grid-cols-5 sm:h-[700px]'  style={{animation:'scrollCompany1 30s linear infinite'}}>
                                        <div className='flex items-center justify-center flex-col'>
                                            <img src={quess} className='w-8/12' alt='quess'/>
                                        </div> 
                                        <div className='relative top-10 flex items-center justify-center flex-col gap-20'>
                                            <img src={tata} className='w-8/12' alt='ola'/>
                                            <img src={lt} className='w-8/12' alt='ola'/>
                                        </div>
                                        <div className='flex items-center justify-center flex-col'>
                                            <img src={ola} className='w-8/12' alt='ola'/>
                                        </div> 
                                        <div className='flex items-center justify-center flex-col gap-20'>
                                            <img src={iss} className='w-8/12' alt='ola'/>
                                            <img src={justdial} className='w-8/12' alt='ola'/>
                                        </div> 
                                        <div className='relative top-10 flex items-center justify-center flex-col'>
                                            <img src={cbre} className='w-8/12' alt='ola'/>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2 sm:grid-cols-5 sm:h-[600px]'  style={{animation:'scrollCompany2 30s linear infinite'}} data-aos="fade-in" data-aos-duration='500'>
                                        <div className='flex items-center justify-center flex-col'>
                                            <img src={quess} className='w-8/12' alt='quess'/>
                                        </div> 
                                        <div className='relative top-10 flex items-center justify-center flex-col gap-20'>
                                            <img src={tata} className='w-8/12' alt='ola'/>
                                            <img src={lt} className='w-8/12' alt='ola'/>
                                        </div>
                                        <div className='flex items-center justify-center flex-col'>
                                            <img src={ola} className='w-8/12' alt='ola'/>
                                        </div> 
                                        <div className='flex items-center justify-center flex-col gap-20'>
                                            <img src={iss} className='w-8/12' alt='ola'/>
                                            <img src={justdial} className='w-8/12' alt='ola'/>
                                        </div> 
                                        <div className='relative top-10 flex items-center justify-center flex-col'>
                                            <img src={cbre} className='w-8/12' alt='ola'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <>
                            <div className='grid grid-cols-2 sm:grid-cols-5 sm:px-40 h-[44rem] sm:h-[32rem]' data-aos="fade-in" data-aos-duration='500'>
                                <div className='relative top-24 flex items-center content-center flex-col '>
                                    <img src={tata} className='w-40' alt='tata'/>
                                    <img src={cbre} className='w-40' alt='cbre'/>
                                    <img src={lt} className='w-40' alt='lt'/>
                                </div>
                                <div className='justify-center flex flex-col items-center'>
                                    <img src={justdial} className='w-40' alt='justdial'/>
                                    <img src={ola} className='w-40' alt='ola'/>
                                    <img src={iss} className='w-40' alt='iss'/>
                                    <img src={quess} className='w-40' alt='quess'/>
                                </div> 
                            </div>
                            </>
                        )
                    }
                {/* </div> */}
            </section>
            <section className='text-primary bg-white p-10 sm:px-20 relative'>
                <div className=' bg-neutral-100 xl:h-[20rem] z-10 w-full top-0 left-0 absolute sm:block'></div>
                <h1 className='text-4xl font-bold leading-normal sm:text-6xl sm:w-6/12 sm:pb-0 xl:leading-relaxed relative z-20'  data-aos="fade-up">
                    Our leadership that strives to innovate
                </h1>
                <div className='grid grid-cols-1 lg:grid-cols-2 sm:gap-12 ' >
                    <FounderCard 
                        imgURL={gaurav}
                        name='Gaurav Bali'
                        designation='Co-founder'
                        description='A Harvard business school alumni Gaurav is the co-Founder of Enlite.
                        In his previous stint, he has worked as the CEO for an outsourcing firm growing its footprint from a single location to a global presence by technology innovation and delivering high-quality services. Gaurav is also the co-founder of an AI startup with focused research on Deep Learning in Unstructured Text & Vision.'
                        />
                    <FounderCard
                        imgURL={garima}
                        name='Garima Bharadwaj'
                        designation='Co-founder'
                        description='Garima the co-founder at Enlite has spent all her working years building some high growth startups. In her previous stint Garima was part of the core team at The Moms co. – a digital-first FMCG company heading business operations, In her earlier stint, she has worked as an early core team member of one of the globally largest hospitality startup– OYO helped the company achieve scale by enabling strategic partnerships and new product category launches.'
                        />
                </div>
            </section>
            <section className='text-primary py-20 sm:pl-20 bg-white xl:pl-0 relative'>
                <h1 className='text-4xl font-bold leading-normal sm:text-6xl sm:w-6/12 sm:pb-0 xl:w-10/12 pl-10 xl:pl-20'  data-aos="fade-up">
                    Awards and Recognitions
                </h1>
                <div className='hidden absolute right-40 text-4xl top-24 flex-row gap-10 md:flex'>
                    <button
                        className='text-primary disabled:text-gray-300'
                        //@ts-ignore
                        disabled={leftArrowDisabled}
                        onClick={() => {
                            // @ts-ignore
                            awards.current.scrollBy({ 
                                top: 0, // could be negative value
                                left: -window.innerWidth/2, 
                                behavior: 'smooth'
                            })              
                            setRightArrowDisabled(false)                
                            var card = document.getElementById('awards-start')?.getBoundingClientRect();
                            var left = card?.left;
                            var right = card?.right;
                            // var width = card?.width;
                            //@ts-ignore
                            if((left && left >=  -window.innerWidth/2) && (right && right <= window.innerWidth)) 
                                setLeftArrowDisabled(true)
                        }}
                    >
                        <IoArrowBackSharp />
                    </button>
                    <button
                        className='text-primary disabled:text-gray-300'
                        disabled={rightArrowDisabled}
                        onClick={async () => {
                            var card = document.getElementById('awards-end')?.getBoundingClientRect();

                            // console.log('1 ', card?.left, card?.right)
                            // @ts-ignore
                            await awards.current.scrollBy({ 
                                top: 0, // could be negative value
                                left: window.innerWidth/2, 
                                behavior: 'smooth'
                            })
                            var left = card?.left;
                            var right = card?.right;
                            // console.log(left, right)
                            // var width = card?.width;
                            //@ts-ignore
                            if((left && left >= 0) && (right && right <= window.innerWidth + window.innerWidth/2)) 
                                setRightArrowDisabled(true)
                            //@ts-ignore
                            // if(awards.current.scrollLeft > 0)
                            setLeftArrowDisabled(false)
                        }}
                    >
                        <IoArrowForwardSharp />
                    </button>
                </div>
                <div className='overflow-scroll w-full h-fit overflow-y-hidden sm:h-min xl:py-4' ref={awards} data-aos='fade-left'>
                    <div id='awards-carousel' className='grid w-[3000px] px-2 py-4 sm:px-10 sm:w-[4500px] sm:gap-20 gap-10 pl-10' style={{gridTemplateColumns:'1px repeat(9, 1fr) 2.5rem'}} >
                        <div id='awards-start' className='bg-transparent h-10 w-10' />
                        {/* <AwardCard title='Accelerator Program' 
                            description='Brinc is a Hong Kong headquartered venture capital and accelerator firm. Enlite Research has been accepted in the Spring 20 loT & Hardware program...' 
                            logo={brinc}
                            articleLink='https://www.goolge.com'
                            /> */}
                        <AwardCard
                                title='Times Of India' 
                                description='Garima Bharadwaj’s Enlite Research has created what they call an operating system (OS) for building management, a device...' 
                                logo={toi}
                                articleLink='https://timesofindia.indiatimes.com/business/india-business/bioreactors-to-building-os-deep-tech-ventures-in-india-are-maturing-fast/articleshow/84881072.cms'
                                />
                        <AwardCard 
                                index='6'
                                title='NASSCOM' 
                                description='We all want to live and work in an environment that makes us feel safe and secure. All this while, the concept of residing in a safe building was to have strong walls, roofs, and other basic amenities...' 
                                logo={nasscom}
                                articleLink='https://www.coe-iot.com/blog/enlite-unleashing-deep-tech-innovation-to-make-buildings-intelligent-and-smart/'
                            />
                        <AwardCard 
                                index='2' 
                                title='YourStory' 
                                description='EnliteResearch, founded by Garima Bharadwaj and Gaurav Bali, have introduced BIOS that they claim is the world’s first intelligent, touch-free building operating system that is sustainable as well...' 
                                logo={yourstory}
                                articleLink='https://yourstory.com/herstory/2020/12/woman-entrepreneur-building-operating-system/amp'
                                />
                        <AwardCard 
                                index='3' 
                                title='Qualcomm' 
                                description='Enlite is making the world’s first contactless and wireless building management system called BIOS or building intelligence operating system....' 
                                logo={qualcom}
                                articleLink='https://www.qualcomm.com/company/location/india/2021-startups'
                            />
                        <AwardCard
                                index='0' 
                                title='The Better India' 
                                description='It took Garima Bharadwaj and Gaurav Bali almost two years to come up with an integrated Building Intelligence Operating System (BIOS) — a wireless, energy-efficient device. Here’s why they came up with their Mumbai-based startup Enlite Research...' 
                                logo={thebetterindia}
                                articleLink='https://www.thebetterindia.com/246668/energy-saving-building-management-system-bios-enlite-research-garima-bharadwaj-gaurav-bali-yos191/'
                            />
                        <AwardCard 
                                index='5' 
                                title='QWEIN' 
                                description='As an Investment Director at Qualcomm Ventures India, I regularly get to meet entrepreneurs who are passionate about building frontier tech companies and transforming the future. I am amazed by the quality and vision of these entrepreneurs, and at the same time...' 
                                logo={qualcom}
                                articleLink='https://www.qualcommventures.com/insights/blog/qwein-the-platform-empowering-women-entrepreneurs/'
                            />
                        <AwardCard 
                                index='1' 
                                title='Industrial Automation' 
                                description='For decades there has been no innovation or invention in the building management industry. There were building integration technologies created for catering to large buildings by the industry leaders like Schneider electric, Honeywell, Siemens and Johnson controls. These...' 
                                logo={industrialautomation}
                                articleLink='https://www.industrialautomationindia.in/interviewsitm/11476/We-decided-to-disrupt-the-building-automation-industry/interviews'
                            />
                        <AwardCard 
                                index='4' 
                                title='Gadgets Now' 
                                description='Garima Bharadwaj’s Enlite Research has created what they call an operating system(OS) for building management, a device so low-cost that they can be used by small and mid-sized buildings to track and control every equipment, peripheral and function in the building...' 
                                logo={gadgetsnow}
                                articleLink='https://www.gadgetsnow.com/tech-news/bioreactors-to-building-os-deep-tech-ventures-in-india-are-maturing-fast/articleshow/84880963.cms'
                            />
                        <AwardCard 
                                index='7'
                                title='A&D Magazine' 
                                description='Garima Bharadwaj, Co-founder, Enlite Research – which is making the world’s first contactless & wireless building management system called BIOS – in this interview with Juili Eklahare, gives details on their BIOS solution...' 
                                logo={ad}
                                articleLink='https://www.industr.com/en/covid-helped-us-deprioritise-our-product-company-strategy-2571475'
                            />
                        <div id='awards-end' className='bg-transparent h-10 w-10 float-right' />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home
