import React, { useEffect, useState } from 'react'
import Select from 'react-select';

import {AiFillBulb } from "react-icons/ai"
import { BsFillLightningChargeFill } from "react-icons/bs"
import { MdPeopleAlt } from "react-icons/md"
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../global/api_url';

const girl_on_phone = require('../../assets/girl-on-phone.png')
const person1 = require('../../assets/connect-people/1.png')
const person2 = require('../../assets/connect-people/2.png')
const person3 = require('../../assets/connect-people/3.png')
const person4 = require('../../assets/connect-people/4.png')
const person5 = require('../../assets/connect-people/5.png')
const person6 = require('../../assets/connect-people/6.png')

interface Openings{
    jobId: string,
    jobTitle: string,
    jobLocation: string,
    // jobDept: string
}

const DepartmentDetails = (
    { jobDept, openings, currentLocation, currentDepartment} : {
        jobDept: string,
        openings: Openings[],
        currentLocation: string,
        currentDepartment: string
    }
) => {

    const [hideDept, setHideDept] = useState<boolean>(false)

    useEffect(() => {
        console.log(openings.filter((job:any) => job.jobLocation === currentLocation))
        if(openings.filter((job:any) => job.jobLocation === currentLocation || currentLocation === '').length > 0)
            setHideDept(false)
        else
            setHideDept(true)
    },[currentLocation, hideDept, openings])
    return (
        <>
        {
            ((jobDept === currentDepartment  || currentDepartment === '') && !hideDept)
            ?
            (
                <div className='border-t-2 border-neutral-200 py-6'>
                    {
                    openings.map((opening:Openings, i) => {
                        console.log(opening)
                        return (
                            (opening.jobLocation === currentLocation || currentLocation === '')
                            ?
                                (
                                    <div className='grid my-4 grid-cols-3 sm:grid-cols-3 md:grid-cols-5'>
                                        {i === 0? <h1 className='text-3xl md:col-span-2 sm:block'>{jobDept}</h1> : <div className='md:col-span-2'></div>}
                                        <div className='block md:hidden'></div>
                                        <div className='block md:hidden'></div>
                                        <div className='flex flex-col col-span-2 md:col-span-2'>
                                            <h1 className='md:text-xl'>{opening.jobTitle}</h1>
                                            <h2 className='font-light'>{opening.jobLocation}</h2>
                                        </div>
                                        <Link to={`/career/${opening.jobId}`} className='bg-primary text-white text-center uppercase flex items-center justify-center w-28'>Apply</Link>
                                    </div>
                                )
                            :
                                null
                        )
                    })
                    }
                </div>
                
            )
            : null
        }
        </>
    )
}
const WhyWorkCard = (
    {title, description, icon, fgColor, bgColor} :
    {title: string, description: string, icon: any, fgColor: string, bgColor:string}
) => {
    return (
        <div className='shadow-md p-8 bg-white text-black sm:py-20  sm:px-4 md:px-6 lg:px-12  md:py-6 lg:py-12 2xl:px-16 ' data-aos="fade-in">
            <div className='flex flex-col gap-5' style={{gridTemplateColumns:"30% 70%"}}>
                <span className='h-12 w-12 flex p-3 items-center justify-center text-2xl sm:text-6xl sm:w-16 sm:h-16' style={{color:fgColor, backgroundColor: bgColor, borderRadius:'4px'}}>
                    {icon}
                </span>
                <h1 className='text-2xl font-bold items-center flex sm:text-2xl lg:text-4xl'>{title}</h1>
            </div>
            <div className='text-neutral-500 text-md font-light'>
                {description}
            </div>
        </div>
    )
}

const Career = () => {

    const [location, setLocation] = useState<string>('')
    const [department, setDepartment] = useState<string>('')
    const [openings, setOpenings] = useState<any[]>(
        // [
        //     {
        //         "jobDept": "Engineering",
        //         "openings": [
        //             {
        //                 "jobId": "BE123",
        //                 "jobTitle": "Backend Engineer",
        //                 "jobLocation": "Remote",
        //                 "jobDept": "Engineering"
        //             },
        //             {
        //                 "jobId": "E123",
        //                 "jobTitle": "Frontend Engineer",
        //                 "jobLocation": "Remote",
        //                 "jobDept": "Engineering"
        //             }
        //         ]
        //     },
        //     {
        //         "jobDept": "Data & Analytics",
        //         "openings": [
        //             {
        //                 "jobId": "DA123",
        //                 "jobTitle": "Data Analyst",
        //                 "jobLocation": "Mumbai",
        //                 "jobDept": "Data & Analytics"
        //             }
        //         ]
        //     }
        // ]
    )
    const [deptData, setDeptData] = useState<any[]>(
        // [
        //     {
        //         value:'', label:'All'
        //     },
        //     {
        //         value:'Engineering', label:'Engineering',
        //     },
        //     {
        //         value:'Data & Analytics', label:'Data & Analytics',
        //     },
        // ]
    )
    const [locationData, setLocationData] = useState<any[]>(
        // [
        //     {
        //         value:'', label:'All'
        //     },
        //     {
        //         value:'Remote', label:'Remote',
        //     },
        //     {
        //         value:'Mumbai', label:'Mumbai',
        //     },
        // ]
    )

    useEffect(() => {
        const fetchOpenings = async() => {
            try{
                // console.log(res.data.result)
                const deptData:any[] = [{
                    value:'', label:'All'
                }];
                const locationData:any[] = [{
                    value:'', label:'All'
                }];
                let locationRes = await axios.get(
                    `${API_BASE_URL}/get-locations`
                )
                locationRes.data.result.forEach((location:any) => {
                    locationData.push(
                        {
                            value:location.jobLocation, label:location.jobLocation
                        }
                    )
                })
                setLocationData(locationData)
                let deptRes = await axios.get(
                    `${API_BASE_URL}/get-departments`
                )
                deptRes.data.result.forEach((dept:any) => {
                    deptData.push(
                        {
                            value:dept.jobDept, label:dept.jobDept
                        }
                    )
                })
                setLocationData(locationData)
                setDeptData(deptData)
                
                let res = await axios.get(
                    `${API_BASE_URL}/openings`
                )
                const data = deptRes.data.result.map((dept: any) => {
                    return {
                        jobDept: dept.jobDept,
                        openings: res.data.result.filter((job:any) => job.jobDept === dept.jobDept)
                    }
                })
                console.log('data', data)
                // console.log(data)
                setOpenings(data)
            }
            catch(err){
                console.log(err)
            }
        } 
        fetchOpenings();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[])

    console.log(location, department)

    return (
        <div>
            <div className='grid grid-row-2 md:grid-cols-5 md:grid-rows-none'>
                <div className='bg-white z-[90] md:col-span-3 relative py-10 pb-0 px-10 xl:pl-20 xl:pr-0 md:py-40'  data-aos="fade-in">
                    <h1 className='text-4xl font-bold py-4 text-primary xl:text-6xl xl:py-10' data-aos="fade-up">Lets innovate together</h1>
                    <p className='text-xl font-light py-2 md:w-6/12' data-aos="fade-in" data-aos-duration='500'>
                        {`We’re driven by creativity, curiosity and a passion for challenging the status quo in everything we do.`}
                    </p>
                    <a href='#apply' className='bg-primary text-white uppercase py-3 px-9 mt-5 inline-block  text-center '>Join Us</a>
                </div>
                <div className='md:col-span-2  grid grid-cols-4 grid-rows-4 relative z-[91] md:top-0'  data-aos="fade-in">
                    <div className='bg-transparent md:bg-white'></div>
                    <div className='bg-blue-400'></div>
                    <div>
                        <img src={person6} alt="person6" className="w-full h-full object-cover" />
                    </div>
                    <div className='bg-blue-600'></div>
                    <div className='bg-transparent md:bg-blue-200'></div>
                    <div>
                        <img src={person5} alt="person5" className="w-full h-full object-cover" />                        
                    </div>
                    <div className='bg-transparent md:bg-blue-400'></div>
                    <div>
                        <img src={person3} alt="person3" className="w-full h-full object-cover" />                        
                    </div>
                    <div className='bg-transparent'></div>
                    <div className='bg-transparent'></div>
                    <div>
                        <img src={person4} alt="person4" className="w-full h-full object-cover" />
                    </div>
                    <div className='bg-transparent md:bg-blue-600'></div>

                    <div className='bg-transparent'></div>
                    <div>
                        <img src={person2} alt="person2" className="w-full h-full object-cover" />
                    </div>
                    <div className='bg-blue-800'></div>
                    <div>
                        <img src={person1} alt="person1" className="w-full h-full object-cover" />
                    </div>
                </div>
            </div>
            <div className='z-[90] relative py-12 px-10 xl:px-44' style={{background:'rgba(234, 235, 242, 1)'}}>
                <div className='hidden lg:inline-block  w-72 h-40 absolute right-0 top-96' style={{background:'rgba(87, 118, 164, 0.05)'}}/>
                <div className='hidden lg:inline-block w-[21rem] h-40 absolute right-0 top-[14rem]' style={{background:'rgba(19, 44, 81, 0.1)'}}/>
                <h1 className='text-4xl font-bold py-4 text-primary xl:text-6xl xl:py-10 md:w-10/12' data-aos="fade-up">A Million Ideas. One Goal. <br/>Perfection.</h1>
                <h2 className='text-xl pt-5 md:text-3xl' data-aos="fade-in">Why work with us?</h2>
                <p className='text-md text-neutral-500 font-light pt-2 md:text-xl md:w-6/12' data-aos="fade-in">
                    {`At Enlite you will have the opportunity to explore your spirit of innovation and work with passionate pathbreakers`}
                </p>
                <div className='flex flex-col gap-10 relative top-16 md:flex-row' >
                    <WhyWorkCard 
                        title='Innovate'
                        description='With an environment that rewards innovation, you are encouraged to experiment and succeed.'
                        fgColor='#05D16F'
                        bgColor='#C3F4E0'
                        icon={<AiFillBulb />} 
                        />
                    <WhyWorkCard 
                        title='Disrupt'
                        description='Break conventions. Create products that enable the future of business and technology.'
                        fgColor='#D1B205'
                        bgColor='#FFFADE'
                        icon={<BsFillLightningChargeFill />} 
                        />
                    <WhyWorkCard 
                        title='Collaborate'
                        description='With a cohort of high-energy, passionate individuals, life at the workplace has never been more rewarding.'
                        fgColor='#DF305B'
                        bgColor='#FFDEE6'
                        icon={<MdPeopleAlt />} 
                        />
                </div>
            </div>
            {
                openings 
                ? 
                    (<div className='bg-white z-[80] relative py-16 px-10 xl:px-60' id="apply">
                        <h1 className='text-4xl py-4 text-black xl:text-6xl xl:py-10' data-aos="fade-up">All Open Roles</h1>
                        <div className='flex flex-col gap-5 md:flex-row' data-aos="fade-in">
                            <Select
                                onChange={({value}: any) => setDepartment(value)}
                                isSearchable={false}
                                placeholder='Departments'
                                options={deptData}
                                className='md:w-48'
                                styles={{
                                    control: () => {
                                        return {
                                            display: 'flex',
                                            flexDirection:'row',
                                            padding: '0.5rem',
                                            background: 'rgba(250, 250, 250, 1)',
                                            color: 'black',
                                        }
                                    },
                                    indicatorSeparator: () => ({
                                        display: 'none'
                                    })
                                    
                                }}
                                />
                            <Select
                                onChange={({value}:any) => setLocation(value)}
                                isSearchable={false}
                                placeholder='Location'
                                options={locationData}
                                className='md:w-48'
                                styles={{
                                    control: () => {
                                        return {
                                            display: 'flex',
                                            flexDirection:'row',
                                            padding: '0.5rem',
                                            background: 'rgba(250, 250, 250, 1)',
                                            color: 'black',
                                        }
                                    },
                                    indicatorSeparator: () => ({
                                        display: 'none'
                                    })
                                    
                                }}
                                />
                        </div>
                        <div className='py-10' data-aos="fade-in">
                            {
                                openings.map((job:any) => {
                                    return(
                                        <DepartmentDetails
                                            jobDept={job.jobDept}
                                            openings={job.openings}
                                            currentLocation={location}
                                            currentDepartment={department}
                                            />
                                    )
                                })
                            }
                        </div>
                    </div>)
                :
                    null
                    }
            <div className='px-10 mb-10 flex flex-col items-center xl:px-44 md:flex-row md:justify-center pt-24'>
                <div className='bg-primary h-4 w-10/12 rounded-t-xl md:h-56 lg:h-[22rem] md:w-12 md:rounded-tr-none md:rounded-l-xl'></div>
                <img src={girl_on_phone} alt="girl on phone" className='w-12/12 shadow-lg md:h-60 lg:h-96' />
                <div className='bg-primary w-10/12 py-5 px-4 rounded-b-lg md:w-6/12 lg:h-[22rem] md:h-56 md:rounded-bl-none md:rounded-r-xl md:p-6 2xl:p-12' >
                    <h1 className='text-bold text-white py-1 md:hidden' >Not able to find a role ?</h1>
                    <p className='text-sm text-stone-300 py-1 md:hidden' >If you want to contribute towards great products, drop us a mail !</p>
                    <h1 className='hidden text-4xl font-bold text-white py-1 w-7/12 md:9/12 lg:7/12 md:text-3xl lg:text-4xl md:inline-block' >We’re looking for innovators</h1>
                    <p className='hidden text-sm text-stone-300 py-3 w-9/12 lg:inline-block' >We’re constantly looking for enthusiastic folks with the drive to change the world. Think you’ve got what it takes? Drop us a note.</p>
                    <Link to="/contact" className='text-white inline-block md:block lg:inline-block text-center bg-black w-full uppercase p-2 mt-5 md:w-4/12 md:py-3'>Contact</Link>
                </div>
            </div>
        </div>
    )
}

export default Career
