import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
import Hamburger from 'hamburger-react'
import ProductMenu from '../ProductMenu/ProductMenu'

const enliteBlue = require("../../assets/logo/blue.png")
const enliteWhite = require("../../assets/logo/white.png")

const Header = (
    {setModalOpen, setModalType, openProductMenu, setOpenProductMenu, blueNavbar, setBlueNavbar}
    : any
) => {
    const [isModalOpen, toggleModal] = useState<boolean>(false)
    // const [openProductMenu, setOpenProductMenu] = useState<boolean>(false)
    return (
        <>
            <nav className={`z-[500] h-24 text-left grid p-6 xl:pl-20 xl:py-10 
                            grid-cols-2 gap-[60%] transition-all duration-400 
                            w-full sm:gap-[10%] lg:gap-[40%] xl:gap-[33%] 2xl:gap-[60%]
                            ${window.location.pathname !== "/" || !blueNavbar ? 'text-primary bg-white' : 'bg-primary text-white'}`} 
                            // data-aos="fade-in"
                >
                <button className='inline-block font-bold text-3xl sm:hidden'>
                    <Hamburger toggled={isModalOpen} toggle={toggleModal}/>
                </button>
                <NavLink to="/" 
                    className='flex items-center content-center'
                    onClick={() => {
                        setOpenProductMenu(false);
                        toggleModal(false);
                        setBlueNavbar(true)
                    }}
                >
                    <img src={window.location.pathname !== "/" || !blueNavbar ? enliteBlue : enliteWhite} alt="enlite" className='w-28'/>
                </NavLink>
                <div className='hidden grid-cols-3 text-xl text-center gap-8 font-medium 
                                sm:grid'>
                    <button className={
                                window.location.pathname !== "/" || !blueNavbar 
                                    ? 'border-white border-b-4 hover:border-primary'
                                    : 'border-primary border-b-4 hover:border-white'
                                    }
                            onClick={() => {
                                setOpenProductMenu((prevVal:boolean) => {return !prevVal});
                                setBlueNavbar((prevVal: boolean) => {return !prevVal})
                            }}
                                >
                                    Products
                                <ProductMenu setModalOpen={setModalOpen} setModalType={setModalType} openProductMenu={openProductMenu} />
                        </button >
                    <NavLink
                        style={({ isActive }) => {
                            return {
                                borderBottom: isActive ? '4px solid rgb(31 84 197 / var(--tw-bg-opacity))' : ''
                            }
                        }}
                        to="/contact" 
                        className={
                            window.location.pathname !== "/" || !blueNavbar 
                                ? 'flex items-center mx-auto border-white border-b-4 hover:border-primary'
                                : 'flex items-center mx-auto border-primary border-b-4 hover:border-white'
                                }
                        onClick={() => {
                            setOpenProductMenu(false);
                            setBlueNavbar(false)
                        }}
                            >
                                Contact</NavLink>
                    <NavLink 
                        style={({ isActive }) => {
                            return {
                                borderBottom: isActive ? '4px solid rgb(31 84 197 / var(--tw-bg-opacity))' : ''
                            }
                        }}
                        to="/career" 
                        className={
                            window.location.pathname !== "/" || !blueNavbar 
                                ? 'flex items-center mx-auto border-white border-b-4 hover:border-primary'
                                : 'flex items-center mx-auto border-primary border-b-4 hover:border-white'
                            }
                        onClick={() => {
                            setOpenProductMenu(false);
                            setBlueNavbar(false)
                        }}
                        >Career</NavLink>
                </div>
            </nav>
            <HamburgerMenu isModalOpen={isModalOpen} toggleModal={toggleModal} setEarlyAccessModalType={setModalType} setOpenEarlyAccessModal={setModalOpen} />
        </>
    )
}

export default Header
