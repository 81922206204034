import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { API_BASE_URL } from '../../global/api_url'
import { useSnackbar } from 'notistack';
import { MOBILE } from '../../global/screen_size'

import CountryCodeJSON from '../../global/country_codes.json';
import { allowed_characters } from '../../global/alphabet_array';

const mumbai = require("../../assets/mumbai.png")
const pune = require("../../assets/pune.png")


const ola = require("../../assets/ola.png")
const tata = require("../../assets/tata.png")
const cbre = require("../../assets/cbre.png")
const lt = require("../../assets/lt.png")
const justdial = require("../../assets/justdial.png")
const iss = require("../../assets/iss.png")
const quess = require("../../assets/quess.png")

const ContactCard = (
    {logo, title, desc, link, buttonText, setAbout} :
    {logo: any, title: string, desc: string, link: string, buttonText: string, setAbout: any}
) => {
    return (
        <div className='bg-white p-8 rounded-md flex flex-col gap-3 shadow-lg z-[100] relative h-56 md:h-64 2xl:p-16' data-aos="fade-in">
            <div className='grid lg:flex flex-col gap-2' style={{gridTemplateColumns: '23% 77%'}}>
                <h1 className='text-3xl text-primary'>{logo}</h1>
                <h1 className='text-2xl font-bold'>{title}</h1>
            </div>
            <p className='font-normal text-neutral-500'>{desc}</p>
            <a href={link} className='text-primary text-bold absolute bottom-5 right-10' onClick={() => setAbout(title)}>Contact {`${buttonText} >`}</a>
        </div>
    )
}

const CenterCard = (
    {city, address, link, imgURL} :
    {city: string, address: string, link: string, imgURL:string}
) => {
    return(
        <div className='py-4 flex flex-col gap-4 lg:p-8 xl:px-16' data-aos="fade-in">
            <h1 className='text-primary font-bold text-2xl'>{city}</h1>
            <p className='text-neutral-500 h-32 md:h-40 lg:h-28'>{address}</p>
            <a href={link} rel='noreferrer' target='_blank' className='text-primary text-bold'>View Map {`>`}</a>
            <img src={imgURL}  alt={city} className='md:w-10/12 xl:w-8/12 2xl:w-8/12'/>
        </div>
    )
}
const Contact = () => {

    const [submitingData, setSubmitingData] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<boolean>(false)
    const [about, setAbout] = useState<string>('')
    const [countryCode, setCountryCode] = useState<string>('+91')
    const [emptyField, setEmptyField] = useState<boolean>(false)

    const {enqueueSnackbar} = useSnackbar()
    useEffect(() => { //go back to top: ;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[])
    
    const handleClickConnect = async (e: any) => {
        e.preventDefault();
        setEmptyField(false)
        setSubmitingData(true)
        if(name === '' || email === '' || number === '' || about === '' || countryCode === '' || emailError)
        {
            setEmptyField(true)
            enqueueSnackbar(
                'Please fill all the fields',
                {
                    variant:'error'
                }
            )
        }
        else
        {
            try{
                let data = new FormData()
                data.append('name', name)
                data.append('number', countryCode + number)
                data.append('email', email)
                data.append('about', about)
                const res = await axios.post(
                    `${API_BASE_URL}/contact-us`,
                    data
                )
                enqueueSnackbar(
                    res.data.message,
                    {
                        variant:'success'
                    }
                )
                setName('')
                setNumber('')
                setEmail('')
                setAbout('')
            }
            catch(err: any){
                console.log(err.message)
            }
        }
        setSubmitingData(false)
    }
    return (
        <div>
            <div className='bg-primary z-[90] relative py-12 px-10 xl:px-20'>
                <div className='bg-blue-600 w-24 absolute right-36 h-24 top-0 z-[100] md:w-32 md:h-32 md:right-64'></div>
                <div className='bg-blue-800 w-20 absolute right-16 top-0 h-28 z-[100] md:w-32 md:h-40 md:right-32'></div>
                {/* <div className='bg-blue-900 w-16 absolute right-0 top-0 h-32 z-[100] md:w-32 md:h-48'></div> */}
                <h1 className='text-4xl font-bold xl:px-2 text-white xl:text-6xl xl:py-10  z-[111] relative' data-aos="fade-up">Take the next step.<br/> Get in touch with us!</h1>
                <div className='flex gap-10 z-[100] flex-col relative top-32 md:grid md:grid-cols-3 md:gap-2 xl:gap-16 2xl:px-16'>
                    <ContactCard 
                        logo={
                            <>
                                <svg width="35" height="41" viewBox="0 0 35 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M34.375 10.25H26.875C26.875 5.075 22.675 0.875 17.5 0.875C12.325 0.875 8.125 5.075 8.125 10.25H0.625V40.25H34.375V10.25ZM17.5 4.625C20.6125 4.625 23.125 7.1375 23.125 10.25H11.875C11.875 7.1375 14.3875 4.625 17.5 4.625ZM17.5 23.375C12.325 23.375 8.125 19.175 8.125 14H11.875C11.875 17.1125 14.3875 19.625 17.5 19.625C20.6125 19.625 23.125 17.1125 23.125 14H26.875C26.875 19.175 22.675 23.375 17.5 23.375Z" fill="#0052CC"/>
                                </svg>
                            </>
                        } 
                        title='Sales' desc='Evaluating our products and need advice before you buy?' link='#contact' buttonText='Sales' setAbout={setAbout}/>
                    <ContactCard 
                        logo={
                            <>
                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25 0.625H0.625V34.375H34.375V10L25 0.625ZM8.125 8.125H17.5V11.875H8.125V8.125ZM26.875 26.875H8.125V23.125H26.875V26.875ZM26.875 19.375H8.125V15.625H26.875V19.375ZM23.125 11.875V4.375L30.625 11.875H23.125Z" fill="#0052CC"/>
                                </svg>
                            </>
                        } 
                        title='Media & Press' desc='Need help regarding media enquiry and press coverage' link='#contact' buttonText='Media' setAbout={setAbout}/>
                    <ContactCard 
                        logo={
                            <>
                                <svg width="35" height="35" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 15.5C19.6437 15.5 23 12.1438 23 8C23 3.85625 19.6437 0.5 15.5 0.5C11.3562 0.5 8 3.85625 8 8C8 12.1438 11.3562 15.5 15.5 15.5ZM15.5 19.25C10.4937 19.25 0.5 21.7625 0.5 26.75V30.5H30.5V26.75C30.5 21.7625 20.5063 19.25 15.5 19.25Z" fill="#0052CC"/>
                                </svg>
                            </>
                        }
                        title='Careers' desc='Want to join the team?' link='/career/#apply' buttonText='Work' setAbout={setAbout}/>
                </div>
            </div>
            <div className='bg-white  relative py-16 pt-32 md:grid md:grid-cols-5' id="contact">
                {
                    window.innerWidth > MOBILE
                    ?
                    (
                        <>
                        <div className='grid grid-rows-3 gap-5 py-5 overflow-x-hidden col-span-2 relative left-[-5px]' data-aos="fade-in">
                            <div className='flex items-center justify-center gap-5 py-2 h-48 px-10 md:px-32'>
                                <img src={ola} alt="ola" className='flex items-center justify-center h-full'/>
                                <img src={cbre} alt="cbre" className='h-full'/>
                            </div>
                            <div className='flex items-center justify-center gap-5 py-2 w-full h-48'>
                                <img src={iss} alt="iss" className=' h-full'/>
                                <img src={quess} alt="quess" className=' h-full'/>
                                <img src={justdial} alt="justdial" className='  h-full'/>
                            </div>
                            <div className='flex items-center justify-center gap-2 py-5 h-48'>
                                <img src={lt} alt="lt" className='h-full'/>
                                <img src={tata} alt="tata" className='h-full'/>
                            </div>
                        </div>
                        <div className='py-8 px-14 xl:px-36 col-span-3'>
                            <h1 className='text-5xl py-4 font-bold text-primary' data-aos="fade-up">Partner with us.<br/>Lets Connect</h1>
                            <form className='flex gap-8 flex-col relative' data-aos="fade-in">
                                <div className='flex flex-col'>
                                    <label className='text-primary'>Name</label>
                                    <input required type='text' placeholder='John Kevine' 
                                        className={`${name.length === 0 && emptyField ? 'border-red-400' : 'border-primary'} border-2 p-2 mt-2`} value={name} 
                                        onChange={e => setName(e.target.value)}
                                        onKeyDown={(evt) => allowed_characters.includes(evt.key) || evt.preventDefault()}
                                        />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='text-primary'>Mobile Number</label>
                                    <div className='grid grid-cols-7 gap-5'>
                                        <select 
                                            className={`bg-white col-span-2 flex border-2 ${emptyField && countryCode === '' ? 'border-red-400' : 'border-primary'} items-center justify-center mt-2 w-full text-center`} 
                                            value={countryCode} onChange={e => setCountryCode(e.target.value)}>
                                            {/* <option selected>India</option> */}
                                            {
                                                CountryCodeJSON.map(
                                                    country => {
                                                        return (
                                                            <option selected={country.dial_code === "+91"} value={country.dial_code}>
                                                                {`${country.name} (${country.dial_code})`}
                                                            </option>
                                                        )
                                                    }
                                                )
                                            }
                                        </select>
                                        <input type='number' min='0' placeholder='0123456789'
                                                className={`${number.length === 0 && emptyField ? 'border-red-400' : 'border-primary'} col-span-5 border-2 p-2 mt-2`} 
                                                required value={number} 
                                                onChange={e => {
                                                    if(e.target.value.length < 11)
                                                        setNumber(e.target.value)
                                                    else 
                                                        return false
                                                }}
                                                onKeyDown={(evt) => {
                                                    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                                                }}
                                                />
                                    </div>
                                </div>
                                <div className='flex flex-col'>
                                    <label className='text-primary'>E - mail</label>
                                    <input 
                                        type='email' 
                                        placeholder='johnkevin@email.com' 
                                        className={`col-span-6 border-2 p-2 mt-2 ${emailError || (emptyField && email.length === 0) ? 'border-red-500' : 'border-primary'}`}
                                        required 
                                        value={email} 
                                        onChange={e => {setEmail(e.target.value); setEmailError(false)}}
                                        onBlur={ 
                                            () => 
                                            !(/\S+@\S+\.\S+/).test(email) ? setEmailError(true) : setEmailError(false)
                                        }
                                        />
                                    {
                                        emailError 
                                            ?
                                            <label className='text-red-400 text-xs py-1'>Please enter valid email address</label>
                                            :
                                            null
                                    }
                                </div>
                                <div className='flex flex-col'>
                                    <label className='text-primary'>Who you are</label>
                                    <select 
                                            className={`bg-white col-span-2 flex border-2 text-neutral-400 border-primary items-center justify-center mt-2 w-full text-center py-2`} 
                                            value={about} onChange={e => {
                                                setAbout(e.target.value)
                                                e.target.style.color = 'black'
                                                }}>
                                            <option value='Interior Designer'>Interior Designer</option>
                                            <option value='Developer'>Developer</option>
                                            <option value='Architect'>Architect</option>
                                            <option value='System Integrator'>System Integrator</option>
                                            <option value='Office Owner'>Office Owner</option>
                                            <option value='Sales'>Sales</option>
                                            <option value='Media & Press'>Media & Press</option>
                                            <option value='Others'>Others</option>
                                        </select>
                                    {/* <input required type='text' placeholder='Interior Designer' 
                                    // className='border-primary border-2 p-2 mt-2' 
                                    className={`${about.length === 0 && emptyField ? 'border-red-400' : 'border-primary'} border-2 p-2 mt-2`}
                                    value={about} onChange={e => setAbout(e.target.value)}/> */}
                                </div>
                                <input type="submit" 
                                        className='bg-primary w-6/12 xl:w-32 text-white p-4 py-3 uppercase disabled:bg-blue-200 cursor-pointer' 
                                        onClick={handleClickConnect} 
                                        disabled={submitingData} 
                                        value={submitingData ? 'Submiting...' : 'Connect'}
                                        />
                            </form>
                    </div>
                        </>
                    )
                    :
                    (
                        <>
                        <div className='py-8 px-14 xl:px-36'>
                        <h1 className='text-4xl font-bold text-primary' data-aos="fade-up">Partner with us. Lets Connect</h1>
                        <form className='flex gap-8 flex-col relative' data-aos="fade-in">
                            <div className='flex flex-col'>
                                <label className='text-primary'>Name</label>
                                <input required type='text' placeholder='John Kevine' 
                                    className={`${name.length === 0 && emptyField ? 'border-red-400' : 'border-primary'} border-2 p-2 mt-2`} value={name} 
                                    onChange={e => setName(e.target.value)}
                                    onKeyDown={(evt) => allowed_characters.includes(evt.key) || evt.preventDefault()}
                                    />
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-primary'>Mobile Number</label>
                                <div className='grid grid-cols-7 gap-5'>
                                    <select 
                                        className={`bg-white col-span-2 flex border-2 ${emptyField && countryCode === '' ? 'border-red-400' : 'border-primary'} items-center justify-center mt-2 w-full text-center`} 
                                        value={countryCode} onChange={e => setCountryCode(e.target.value)}>
                                        {
                                            CountryCodeJSON.map(
                                                country => {
                                                    return (
                                                        <option selected={country.dial_code === "+91"} value={country.dial_code}>
                                                            {`${country.name} (${country.dial_code})`}
                                                        </option>
                                                    )
                                                }
                                            )
                                        }
                                    </select>
                                    <input type='number' min='0' placeholder='0123456789' maxLength={10}
                                            className={`${number.length === 0 && emptyField ? 'border-red-400' : 'border-primary'} col-span-5 border-2 p-2 mt-2`} 
                                            required value={number} 
                                            onChange={e => setNumber(e.target.value)}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            />
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-primary'>E - mail</label>
                                <input 
                                    type='email' 
                                    placeholder='johnkevin@email.com' 
                                    className={`col-span-6 border-2 p-2 mt-2 ${emailError || (emptyField && email.length === 0) ? 'border-red-500' : 'border-primary'}`}
                                    required 
                                    value={email} 
                                    onChange={e => {setEmail(e.target.value); setEmailError(false)}}
                                    onBlur={ 
                                        () => 
                                        !(/\S+@\S+\.\S+/).test(email) ? setEmailError(true) : setEmailError(false)
                                    }
                                    />
                                {
                                    emailError 
                                        ?
                                        <label className='text-red-400 text-xs py-1'>Please enter valid email address</label>
                                        :
                                        null
                                }
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-primary'>Who you are</label>
                                <select 
                                        className={`bg-white col-span-2 flex border-2 text-neutral-300 border-primary items-center justify-center mt-2 w-full text-center  py-2`} 
                                        value={about} onChange={e => {
                                            setAbout(e.target.value)
                                            e.target.style.color = 'black'
                                            }}>
                                        <option value='Interior Designer'>Interior Designer</option>
                                        <option value='Developer'>Developer</option>
                                        <option value='Architect'>Architect</option>
                                        <option value='System Integrator'>System Integrator</option>
                                        <option value='Office Owner'>Office Owner</option>
                                        <option value='Others'>Others</option>
                                    </select>
                                {/* <input required type='text' placeholder='Interior Designer' 
                                // className='border-primary border-2 p-2 mt-2' 
                                className={`${about.length === 0 && emptyField ? 'border-red-400' : 'border-primary'} border-2 p-2 mt-2`}
                                value={about} onChange={e => setAbout(e.target.value)}/> */}
                            </div>
                            <input type="submit" 
                                    className='bg-primary w-6/12 text-white p-4 py-3 uppercase disabled:bg-blue-200 cursor-pointer' 
                                    onClick={handleClickConnect} 
                                    disabled={submitingData} 
                                    value={submitingData ? 'Submiting...' : 'Connect'}
                                    />
                        </form>
                    </div>
                        <div className='grid grid-rows-3 gap-5 py-5 overflow-x-hidden' data-aos="fade-in">
                            <div className='flex items-center justify-center gap-5 py-5 h-48 px-10'>
                                <img src={ola} alt="ola" className='flex items-center justify-center h-full'/>
                                <img src={cbre} alt="cbre" className='h-full'/>
                            </div>
                            <div className='flex items-center justify-center gap-5 py-5 w-full h-48'>
                                <img src={iss} alt="iss" className=' h-full'/>
                                <img src={quess} alt="quess" className=' h-full'/>
                                <img src={justdial} alt="justdial" className='  h-full'/>
                            </div>
                            <div className='flex items-center justify-center gap-5 py-5 h-48'>
                                <img src={lt} alt="lt" className='h-full'/>
                                <img src={tata} alt="tata" className='h-full'/>
                            </div>
                        </div>
                        </>
                    )
                }
            </div>
            <div className='bg-blue-50 z-[90] relative py-20 xl:h-[45rem] xl:mb-28 px-10 md:px-32 2xl:h-[55rem]'>
                <h1 className='text-4xl font-bold text-primary' data-aos="fade-up">Our innovation centres</h1>
                <div className='md:grid-cols-2 md:grid'>
                    <CenterCard city='Mumbai' address='3rd Floor, Wework Chromium, Jogeshwari - Vikhroli Link Rd, Powai, Mumbai, Maharashtra 400076' link='https://goo.gl/maps/vF4r9PBbdLQwGXVr7' imgURL={mumbai} />
                    <CenterCard city='Qatar' address='Customer GRC, CMT Industries, CMT International Manateq Logistics Park, Building #5, Street #3018, Zone 91, Birkat Al Awamer, P.O.Box 11353 Doha, Qatar' link='https://goo.gl/maps/UYd95KZCZgFgKxzh9' imgURL={pune} />
                </div>
            </div>
        </div>
    )
}

export default Contact
