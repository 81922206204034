import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from "react-icons/ai"
import { API_BASE_URL } from '../../global/api_url'
import { GrNext } from "react-icons/gr"
import { ImHourGlass } from "react-icons/im"

const enlightenable = require("../..//assets/eeLogo.png")
const knowyourbuilding = require("../../assets/kybLogo.png")

interface IProps {
    type: 'kyb' | 'ee',
    modalOpen: boolean,
    setModalOpen: any
}

const EnrollEarlyAccess = (
    {type, modalOpen, setModalOpen} : IProps
) => {

    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<boolean>(false)
    const [submitingData, setSubmitingData] = useState<boolean>(false)

    const { enqueueSnackbar } = useSnackbar()
    
    useEffect(() => {
        if(modalOpen)
        {
            const blurBG = document.getElementById('blur-bg');
            blurBG?.classList.add('blur')
            blurBG?.classList.add('stop-scroll')
        }
    },[modalOpen])

    const handleClickConnect = async (e: any) => {
        setSubmitingData(true)
        setEmailError(false)
        e.preventDefault();
        if(email === '')
        {
            setEmailError(true)
            enqueueSnackbar(
                'Please fill all the fields',
                {
                    variant:'error'
                }
            )
        }
        else
        {
            try{
                let data = new FormData()
                data.append('email', email)
                data.append('product', type)
                const res = await axios.post(
                    `${API_BASE_URL}/store-email`,
                    data
                )
                enqueueSnackbar(
                    res.data.message,
                    {
                        variant:'success'
                    }
                )
                setEmail('')
            }
            catch(err: any){
                console.log(err.message)
            }
        }
        setSubmitingData(false)
    }
    return (
        <>
        {
            modalOpen
            ?
            (
                <div className='h-screen w-screen z-[899] fixed flex items-center justify-center top-0 left-0 modal-bg-backdrop'>
                    <div className='bg-white w-screen h-screen md:h-[90vh] md:w-[90vw] flex sm:grid relative z-[900] md:rounded-md modal-bg-shadow' style={{gridTemplateColumns:'40% 60%'}} data-aos="zoom-in" data-aos-duration='200'>
                        <div className='relative md:static'>
                            <div className='p-10 overflow-y-scroll md:overflow-y-visible h-full relative z-[101] flex-col flex gap-5 bg-white sm:gap-10'>
                                <button 
                                    onClick={
                                        () => {
                                            setModalOpen(false)           
                                            const blurBG = document.getElementById('blur-bg');
                                            blurBG?.classList.remove('blur')
                                            blurBG?.classList.remove('stop-scroll')
                                            setEmail('')
                                            setEmailError(false)
                                    }} 
                                    className='text-3xl font-bold md:hidden'>
                                    <AiOutlineClose />
                                </button>
                                <img src={type === 'kyb' ? knowyourbuilding : enlightenable} alt='logo' className='w-2/12'/>
                                <h1 className='text-primary text-2xl font-bold relative z-[105]'>
                                    Sign Up For Early Access
                                </h1>
                                <p className='text-neutral-400 text-md'>
                                    {
                                        type === 'kyb'
                                        ? `Get in early on the game changing, value driven real estate technology platform, and experience the forefront of cutting edge real estate technology innovation. Get access to exclusive updates and promotional deals that you just can’t beat. We’ll be in touch with more`
                                        : `Get in early and enjoy the limitless benefits of a future facing universal access control and identification mechanism and get access to exclusive updates and promotional deals that you just can’t beat. We’ll be in touch with more`
                                    }
                                </p>
                                <label className='text-primary text-2xl'>
                                    E-mail
                                </label>
                                <div className='flex flex-col md:w-[130%] overflow-y-visible md:grid md:grid-cols-10'>
                                    <input 
                                        onBlur={ 
                                            () => 
                                            !(/\S+@\S+\.\S+/).test(email) ? setEmailError(true) : setEmailError(false)
                                        }
                                        required type='email' placeholder='example@email.com' 
                                        className={`p-2 ${emailError ? 'border-red-400' : 'border-black'} border-2 border-r-0 hidden md:inline-block relative z-[999] col-span-9 outline-none`} 
                                        value={email} onChange={e => setEmail(e.target.value)}/>
                                    <input 
                                        onBlur={ 
                                            () => 
                                            !(/\S+@\S+\.\S+/).test(email) ? setEmailError(true) : setEmailError(false)
                                        }
                                        required type='email' placeholder='example@email.com' 
                                        className={`p-2 ${emailError ? 'border-red-400' : 'border-black'} border-2 md:hidden`} 
                                        value={email} onChange={e => setEmail(e.target.value)}/>
                                    <button 
                                        onClick={handleClickConnect}
                                        disabled={submitingData || emailError}
                                        className={`${emailError ? 'border-red-400' : 'border-black'} border-2 col-span-1 bg-white border-l-0 shadow-none justify-center items-center hidden md:flex`}>
                                        {
                                            submitingData ? <ImHourGlass /> : <GrNext />
                                        }
                                    </button>
                                    {
                                        emailError 
                                            ?
                                            <label className='text-red-400 text-xs pt-2 col-span-6' >Please enter valid email address</label>
                                            :
                                            null
                                    }
                                </div>
                                <input type='submit' 
                                        className='bg-primary w-full uppercase text-white py-3 disabled:bg-blue-200 md:hidden' 
                                        disabled={submitingData || emailError} 
                                        onClick={handleClickConnect}
                                        value={submitingData ? 'Please wait..' : 'Connect'}
                                        />
                                <div className='bg-blue-600 w-40 absolute right-0 h-12 top-0 z-[102] md:w-96 md:hidden'></div>
                                <div className='bg-blue-800 w-32 absolute right-0 top-12 h-12 z-[102] md:w-80 md:hidden md:top-40'></div>
                                <div className='bg-blue-900 w-24 absolute right-0 top-24 h-12 z-[102] md:w-64 md:hidden md:top-80'></div>
                            </div>
                            <div className='hidden absolute bg-blue-600 w-36  right-32 h-48 bottom-0 z-[100] md:inline-block'></div>
                            <div className='hidden absolute bg-blue-800 w-36  right-[17rem] bottom-0 h-[20rem] z-[100] md:inline-block'></div>
                            <div className='hidden absolute bg-blue-900 w-36  right-[26rem] bottom-0 h-40 z-[100] md:inline-block'></div>
                        </div>
                        <div className='hidden sm:inline-block bg-primary'>
                            <button 
                                onClick={
                                    () => {
                                        setModalOpen(false)           
                                        const blurBG = document.getElementById('blur-bg');
                                        blurBG?.classList.remove('blur')
                                        blurBG?.classList.remove('stop-scroll')
                                        setEmail('')
                                        setEmailError(false)
                                }} 
                                className='text-3xl font-bold absolute right-10 top-10 text-white'>
                                <AiOutlineClose />
                            </button>
                        </div>
                    </div>
                </div>
            )
            :
                null
        }
        </>
    )
}

export default EnrollEarlyAccess
